<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <div
        v-for="(item, index) in $t('employeeStories.ES_modular')"
        :key="index"
      >
      <!--  v-if="index % 2 == 0" -->
        <div class="staff">
          <img :src="item.img" alt="" />
          <div>
            <div class="contOne">
              <div class="tip" v-html="item.tipOne"></div>
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="tipTwo">
              {{ item.tipTwo }}
            </div>
          </div>
        </div>
        <!-- <div class="staff staffTwo" v-if="index % 2 != 0">
          <div>
            <div class="contOne contTwo">
              <div class="tip" v-html="item.tipOne"></div>
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="tipTwo">
              {{ item.tipTwo }}
            </div>
          </div>
          <img :src="item.img" alt="" />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：招聘人才-员工故事
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-15 09:17
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "人才招聘",
          title_en: "Talent recruitment",
          path: "recruitment",
        },
        {
          title_zh: "员工故事",
          title_en: "Employee stories",
          path: "",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 78px 310px;
  background: #f6f6f6;
}

.staff {
  background: #ffffff;
  padding: 60px 160px 21px 80px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.staff img {
  width: 160px;
  height: 160px;
  margin-right: 60px;
}

.contOne {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}

.contTwo{
  justify-content: flex-end;
}

.name {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #222222;
  // margin-bottom: 34px;
}

.tip {
  // font-size: 30px;
  font-size: 22px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #1a2a60;
  line-height: 46px;
  // margin-bottom: 48px;
  margin-right: 14px;
}

.tipTwo {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  line-height: 36px;
  margin-bottom: 39px;
}

.staffTwo {
  padding: 60px 80px 21px 145px;
}

.staffTwo > div {
  text-align: right;
}

.staffTwo img {
  margin-left: 60px;
}
</style>